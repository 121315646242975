import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Loggedincontext } from '../../Loggedincontext.js';
import { Routedispatcher } from '../../Routedispatcher.js';
import ProgressBar from '@ramonak/react-progress-bar';
import { FetchUserProfile } from '../Dashboard/API_Dashboard/UserProfile_API';
import { DashboardRoutedispcontext } from '../Dashboard/DashboardRoutedispcontext.js';
import { useQuery } from 'react-query';
import styles from '../Dashboard/Generalfiles/CSS_GENERAL/dashgeneral.module.css';
import userprofilestyles from './Userprofile.module.css';
import { BsCalendar4Event } from 'react-icons/bs';
import { BsGlobe } from 'react-icons/bs';
import { BsTelephone } from 'react-icons/bs';
// import '../Dashboard/Generalfiles/CSS_GENERAL/dashbootstrap.css';

const Userprofileloggedin = (props) => {
    let history = useHistory();
    const { userprofilelink } = useParams();
    const FetchUserProfileQuery = useQuery(['FetchUserProfile' + userprofilelink], () => FetchUserProfile({ profilelink: userprofilelink }), {
        keepPreviousData: true,
        staleTime: 5000000000,
    });
    const { routedispatchercontext } = React.useContext(Routedispatcher);
    const { isloggedincontext, userloggedobjcontext, setuserloggedobjcontext } = React.useContext(Loggedincontext);
    const { chathead_openchatfunccontext } = React.useContext(DashboardRoutedispcontext);
    useEffect(() => {
        var pagetitle = 'Profile';
        userloggedobjcontext.pagetitle = pagetitle;
        setuserloggedobjcontext(userloggedobjcontext);
        document.title = pagetitle;
    }, []);
    useEffect(() => {
        if (FetchUserProfileQuery.isSuccess) {
            if (FetchUserProfileQuery.data.data.status) {
                var pagetitle = FetchUserProfileQuery.data.data.userinfo.username + ' | Profile';
                userloggedobjcontext.pagetitle = pagetitle;
                setuserloggedobjcontext(userloggedobjcontext);
                document.title = pagetitle;
            }
        }
    }, [FetchUserProfileQuery.isSuccess]);
    return (
        <div class="row m-0">
            {FetchUserProfileQuery.isFetching && (
                <div class="card-container-whitesmoke col-lg-12 p-3 mt-2 pl-sm-3 pr-sm-3 justify-content-center d-sm-flex" style={{ position: 'relative' }}>
                    <section class="about hero pt-2 pb-2 pl-4 pr-4">
                        <div class="d-flex pt-0 mt-0 text-center justify-content-center align-items-center w-100 h-100">
                            <span class="loader-1"></span>
                        </div>
                    </section>
                </div>
            )}
            {!FetchUserProfileQuery.isFetching && FetchUserProfileQuery.isSuccess && FetchUserProfileQuery.data.data.status && (
                <div class="row w-100 m-0">
                    <div class="col-lg-12 mb-3 p-0">
                        <div class={styles.card_container_whitesmoke + ' animate__animated animate__slideInUp '} style={{ minHeight: '490px' }}>
                            <div class="d-lg-block d-md-none">
                                <div class="row m-0">
                                    <div class="col-lg-10 p-0 col-md-12">
                                        <div class={userprofilestyles.profile_pic_container}>
                                            <img src={'https://spotearned.com/' + FetchUserProfileQuery.data.data.userinfo.profileurl} class={userprofilestyles.profile_pic + ' img-fluid '} />
                                        </div>
                                        <p class="text-capitalize m-0 p-0 fw-500" style={{ fontSize: '17px' }}>
                                            {FetchUserProfileQuery.data.data.userinfo.username}
                                        </p>
                                        <p>{FetchUserProfileQuery.data.data.userinfo.biography}</p>
                                    </div>
                                    <div class="col-lg-2 p-0">
                                        <div class="row m-0 d-flex justify-content-between">
                                            <div class={styles.general_icon}>
                                                {isloggedincontext && (
                                                    <a
                                                        onClick={() => {
                                                            var chatobj = {
                                                                profilelink: FetchUserProfileQuery.data.data.userinfo.profilelink,
                                                                username: FetchUserProfileQuery.data.data.userinfo.username,
                                                                profileurl: FetchUserProfileQuery.data.data.userinfo.profileurl,
                                                                id: FetchUserProfileQuery.data.data.userinfo.id,
                                                            };
                                                            chathead_openchatfunccontext(chatobj);
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                        class="text-center justify-content-center align-items-center d-flex m-md-auto"
                                                    >
                                                        <i class="fa fa-comments"></i>
                                                    </a>
                                                )}
                                            </div>
                                            <a
                                                href={FetchUserProfileQuery.data.data.userinfo.fblink}
                                                target="_blank"
                                                class="text-center justify-content-center align-items-center d-flex m-md-auto text"
                                            >
                                                <div class={styles.general_icon}>
                                                    <i class="bx bxl-facebook"></i>
                                                </div>
                                            </a>
                                            <a
                                                href={FetchUserProfileQuery.data.data.userinfo.instlink}
                                                target="_blank"
                                                class="text-center justify-content-center align-items-center d-flex m-md-auto text-decoration-none"
                                            >
                                                <div class={styles.general_icon}>
                                                    <i class="bx bxl-instagram"></i>
                                                </div>
                                            </a>
                                            <a
                                                href={FetchUserProfileQuery.data.data.userinfo.youtlink}
                                                target="_blank"
                                                class="text-center justify-content-center align-items-center d-flex m-md-auto text"
                                            >
                                                <div class={styles.general_icon}>
                                                    <i class="bx bxl-vimeo"></i>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row m-0 d-none d-md-block">
                                <div class="col-lg-10 p-0 col-md-12">
                                    <div class="col-md-4 ml-auto mr-auto mb-3">
                                        <img src={'https://spotearned.com/' + FetchUserProfileQuery.data.data.userinfo.profileurl} class={userprofilestyles.profile_pic_resp + ' img-fluid '} />
                                    </div>
                                    <div class="col-md-3 ml-auto mr-auto mb-2 p-0">
                                        <div class="row m-0 d-flex justify-content-between">
                                            <div class={styles.general_icon}>
                                                {isloggedincontext && (
                                                    <a
                                                        onClick={() => {
                                                            var chatobj = {
                                                                profilelink: FetchUserProfileQuery.data.data.userinfo.profilelink,
                                                                username: FetchUserProfileQuery.data.data.userinfo.username,
                                                                profileurl: FetchUserProfileQuery.data.data.userinfo.profileurl,
                                                                id: FetchUserProfileQuery.data.data.userinfo.id,
                                                            };
                                                            chathead_openchatfunccontext(chatobj);
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                        class="text-center justify-content-center align-items-center d-flex m-md-auto"
                                                    >
                                                        <i class="fa fa-comments"></i>
                                                    </a>
                                                )}
                                            </div>
                                            <a
                                                href={FetchUserProfileQuery.data.data.userinfo.fblink}
                                                target="_blank"
                                                class="text-center justify-content-center align-items-center d-flex m-md-auto text"
                                            >
                                                <div class={styles.general_icon}>
                                                    <i class="bx bxl-facebook"></i>
                                                </div>
                                            </a>
                                            <a
                                                href={FetchUserProfileQuery.data.data.userinfo.instlink}
                                                target="_blank"
                                                class="text-center justify-content-center align-items-center d-flex m-md-auto text-decoration-none"
                                            >
                                                <div class={styles.general_icon}>
                                                    <i class="bx bxl-instagram"></i>
                                                </div>
                                            </a>
                                            <a
                                                href={FetchUserProfileQuery.data.data.userinfo.youtlink}
                                                target="_blank"
                                                class="text-center justify-content-center align-items-center d-flex m-md-auto text"
                                            >
                                                <div class={styles.general_icon}>
                                                    <i class="bx bxl-vimeo"></i>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <p class="text-capitalize m-0 p-0 fw-500" style={{ fontSize: '17px' }}>
                                        {FetchUserProfileQuery.data.data.userinfo.username}
                                    </p>

                                    <p class="text-center">{FetchUserProfileQuery.data.data.userinfo.biography}</p>
                                </div>
                            </div>
                            <div class="d-flex text-center justify-content-center align-items-center mt-3">
                                <a href={'https://spotearned.com/' + FetchUserProfileQuery.data.data.userinfo.profilelink} target="_self">
                                    <button class={`${styles.btnn} ${styles.btn2}` + ' text-center m-auto '}>
                                        <svg>
                                            <rect x="0" y="0" fill="" width="100%" height="100%"></rect>
                                        </svg>
                                        <div class="pl-2 pr-2 fw-500">Portfolio</div>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                    {FetchUserProfileQuery.data.data.userinfo.type == 'user' && (
                        <div class="col-lg-12 p-0 mb-3">
                            <div class={styles.card_container_whitesmoke + ' animate__animated animate__slideInUp '}>
                                <div class="col-lg-12 p-0 mb-2">
                                    <div class={styles.section_title}>
                                        <p class="m-0">Categories</p>
                                    </div>
                                </div>
                                <div class="col-lg-12 p-0">
                                    <div class="row m-0 w-100">
                                        {FetchUserProfileQuery.data.data.freelancer_subcats.map((item, index) => {
                                            return (
                                                <div class="col-lg-3 col-md-6 mb-3">
                                                    <div class={styles.card}>
                                                        <p className={styles.text_overflow + ' m-0 font-15 fw-500 text-capitalize '}>{item.catname}</p>
                                                        <p className={styles.text_overflow + ' m-0 font-16 fw-500 txt-secondary text-capitalize '}>{item.subcatname}</p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div class="col-lg-12 p-0 mb-3">
                        <div class={styles.card_container_whitesmoke}>
                            <div class="col-lg-12 p-0 mb-2">
                                <div class={styles.section_title}>
                                    <p class="m-0">Personal Information</p>
                                </div>
                            </div>
                            <div class="col-lg-12 p-0">
                                <div class="row m-0 w-100">
                                    <div class={' col-xl-3 col-lg-6 col-md-6 font-15 fw-500 d-flex align-items-center mb-md-3 pl-1 pr-1'}>
                                        <div class={styles.icon_cont + ' mr-1 mb-auto '}>
                                            <i class="txt-secondary2 h-100 pl-2 pr-2 d-flex align-items-center justify-content-center">
                                                <BsCalendar4Event />
                                            </i>
                                        </div>
                                        <div class="row m-0">
                                            <p class="col-lg-12 m-0 p-0 fw-500 font-15">{FetchUserProfileQuery.data.data.userinfo.type == 'user' ? 'Birth Date' : 'Established Date'}</p>
                                            <p class={styles.text_overflow + ' col-lg-10 m-0 p-0 txt-secondary fw-500 font-16 '}>{FetchUserProfileQuery.data.data.userinfo.age}</p>
                                        </div>
                                    </div>
                                    <div class={' col-xl-3 col-lg-6 col-md-6 font-15 fw-500 d-flex align-items-center mb-md-3 pl-1 pr-1'}>
                                        <div class={styles.icon_cont + ' mr-1 mb-auto '}>
                                            <i class="txt-secondary2 h-100 pl-2 pr-2 d-flex align-items-center justify-content-center">
                                                <BsGlobe />
                                            </i>
                                        </div>
                                        <div class="row m-0">
                                            <p class="col-lg-12 m-0 p-0 fw-500 font-15 text-capitalize">country</p>
                                            <p class={styles.text_overflow + ' col-lg-10 m-0 p-0 txt-secondary fw-500 font-16 '}>{FetchUserProfileQuery.data.data.userinfo.country}</p>
                                        </div>
                                    </div>
                                    <div class={' col-xl-3 col-lg-6 col-md-6 font-15 fw-500 d-flex align-items-center mb-md-3 pl-1 pr-1'}>
                                        <div class={styles.icon_cont + ' mr-1 mb-auto '}>
                                            <i class="txt-secondary2 h-100 pl-2 pr-2 d-flex align-items-center justify-content-center">
                                                <BsTelephone />
                                            </i>
                                        </div>
                                        <div class="row m-0">
                                            <p class="col-lg-12 m-0 p-0 fw-500 font-15 text-capitalize">phone</p>
                                            <p class={styles.text_overflow + ' col-lg-10 m-0 p-0 txt-secondary fw-500 font-16 '}>{FetchUserProfileQuery.data.data.userinfo.mobile}</p>
                                        </div>
                                    </div>
                                    <div class={' col-xl-3 col-lg-6 col-md-6 font-15 fw-500 d-flex align-items-center mb-md-3 pl-1 pr-1'}>
                                        <div class={styles.icon_cont + ' mr-1 mb-auto '}>
                                            <i class="txt-secondary2 h-100 pl-2 pr-2 d-flex align-items-center justify-content-center">
                                                <i class="fa fa-at"></i>
                                            </i>
                                        </div>
                                        <div class="row m-0 w-100">
                                            <p class="col-lg-12 m-0 p-0 fw-500 font-15 text-capitalize">Email</p>
                                            <p class={styles.text_overflow + ' col-lg-10 col-sm-9 m-0 p-0 txt-secondary fw-500 font-16 '}>{FetchUserProfileQuery.data.data.userinfo.email}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {FetchUserProfileQuery.data.data.endorse.length != 0 && (
                        <div class="col-lg-12 p-0 mb-3">
                            <div class={styles.card_container_whitesmoke}>
                                <div class="col-lg-12 p-0 mb-2">
                                    <div class={styles.section_title}>
                                        <p class="m-0">Endorsements</p>
                                    </div>
                                </div>
                                <div class="col-lg-12 p-0">
                                    <div class="row m-0 w-100">
                                        {FetchUserProfileQuery.data.data.endorse.map((item, index) => {
                                            return (
                                                <div class="col-xl-3 col-lg-4 col-md-6 mb-3 p-sm-0">
                                                    <a
                                                        class="pointer"
                                                        onClick={() => {
                                                            routedispatchercontext('/' + item.profilelink + '/profile');
                                                        }}
                                                    >
                                                        <div class={`${styles.card} ${styles.hover_card} ${styles.primary_link_hover_container}` + ' p-2 pointer'}>
                                                            <div class="row m-0 ">
                                                                <div class={styles.general_profile_image_container + ' mr-2 m-sm-auto '}>
                                                                    <img src={'https://spotearned.com/' + item.profileurl} alt="" />
                                                                </div>
                                                                <p
                                                                    class={
                                                                        `${styles.text_overflow} ${styles.primary_link_hover}` +
                                                                        ' col-xl-9 col-lg-8 col-md-9 m-0 p-0 fw-500 font-16 mt-auto mb-auto text-capitalize pr-0 text-sm-center '
                                                                    }
                                                                >
                                                                    {item.username}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Circle Card */}
                    {FetchUserProfileQuery.data.data.circle.length != 0 && (
                        <div class="col-lg-12 p-0 mb-3">
                            <div class={styles.card_container_whitesmoke}>
                                <div class="col-lg-12 p-0 mb-2">
                                    <div class={styles.section_title}>
                                        <p class="m-0">Your Circle</p>
                                    </div>
                                </div>
                                <div class="col-lg-12 p-0">
                                    <div class="row m-0 w-100">
                                        {FetchUserProfileQuery.data.data.endorse.map((item, index) => {
                                            return (
                                                <div class="col-xl-3 col-lg-4 col-md-6 mb-3 p-sm-0">
                                                    <a
                                                        class="pointer"
                                                        onClick={() => {
                                                            routedispatchercontext('/' + item.profilelink + '/profile');
                                                        }}
                                                    >
                                                        <div class={`${styles.card} ${styles.hover_card} ${styles.primary_link_hover_container}` + ' p-2 pointer'}>
                                                            <div class="row m-0 pl-md-2 pr-md-2 ">
                                                                <div class={styles.general_profile_image_container + ' mr-2 m-sm-auto '}>
                                                                    <img src={'https://spotearned.com/' + item.profileurl} alt="" />
                                                                </div>
                                                                <p
                                                                    class={
                                                                        `${styles.text_overflow} ${styles.primary_link_hover}` +
                                                                        '  col-xl-9 col-lg-8 col-md-9 m-0 p-0 fw-500 font-16 mt-auto mb-auto text-capitalize text-sm-center '
                                                                    }
                                                                >
                                                                    {item.username}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* Skills Card */}

                    {FetchUserProfileQuery.data.data.userinfo.type != 'agency' && FetchUserProfileQuery.data.data.skills.length != 0 && (
                        <div class="col-lg-12 p-0 mb-3">
                            <div class={styles.card_container_whitesmoke}>
                                <div class="col-lg-12 p-0 mb-2">
                                    <div class={styles.section_title}>
                                        <p class="m-0">Skills</p>
                                    </div>
                                </div>
                                <div class="col-lg-12 p-0">
                                    <div class="row m-0">
                                        {FetchUserProfileQuery.data.data.skills.map((item, index) => {
                                            return (
                                                <div class="col-lg-6 mb-2">
                                                    <span class="fw-500 text-capitalize pb-0">{item.skillname}</span>
                                                    <ProgressBar
                                                        completed="60"
                                                        bgColor="#FFBC00"
                                                        borderRadius="50px"
                                                        baseBgColor="#f2f3f5"
                                                        transitionDuration="5s"
                                                        isLabelVisible={false}
                                                        animateOnRender={true}
                                                        className="progressBackground"
                                                        height="10px"
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {FetchUserProfileQuery.data.data.paymentmethod.length != 0 && (
                        <div class="col-lg-12 p-0 mb-3">
                            <div class={styles.card_container_whitesmoke}>
                                <div class="col-lg-12 p-0 mb-2">
                                    <div class={styles.section_title}>
                                        <p class="m-0">Payment Methods</p>
                                    </div>
                                </div>
                                <div class="col-lg-12 p-0">
                                    <div class="row m-0">
                                        {FetchUserProfileQuery.data.data.paymentmethod.map((item, index) => {
                                            return (
                                                <div class="col-lg-3 col-md-6 mb-3">
                                                    <div class={styles.card + ' p-2 '}>
                                                        <div class="row m-0 d-flex align-items-center pt-2 pb-2">
                                                            <div class="col-lg-10 col-md-10 col-sm-10 mt-auto mb-auto pl-1">
                                                                <p class="text-capitalize m-0 font-15 fw-500">{item.pmethodname}</p>
                                                            </div>
                                                            <div class="col-lg-2 col-md-2 col-sm-2 text-right">
                                                                <i class="fas fa-dollar-sign text-gray-300 ml-auto" style={{ fontSize: '22px' }}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {FetchUserProfileQuery.data.data.phonenumbers.length != 0 && (
                        <div class="col-lg-12 p-0 mb-3">
                            <div class={styles.card_container_whitesmoke}>
                                <div class="col-lg-12 p-0 mb-2">
                                    <div class={styles.section_title}>
                                        <p class="m-0">Other Phone Numbers</p>
                                    </div>
                                </div>
                                <div class="col-lg-12 p-0">
                                    <div class="row m-0">
                                        {FetchUserProfileQuery.data.data.phonenumbers.map((item, index) => {
                                            return (
                                                <div class="col-lg-3 col-md-6 mb-3">
                                                    <div class={styles.card + ' p-2 '}>
                                                        <div class="row m-0 d-flex align-items-center pt-2 pb-2">
                                                            <div class="col-lg-12 col-md-10 col-sm-10 mt-auto mb-auto text-center">
                                                                <p class="text-capitalize m-0 font-15 fw-500">{item.title}</p>
                                                            </div>
                                                            <div class="col-lg-12 col-md-10 col-sm-10 mt-auto mb-auto text-center">
                                                                <p class="text-capitalize m-0 font-15 fw-500" style={{ color: 'grey', fontSize: '14px' }}>
                                                                    {item.mnumber}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Userprofileloggedin;
