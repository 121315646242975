import React, { Component } from 'react';
// import Cookies from 'universal-cookie';
import axios from 'axios';
const axiosheaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};
export const Loggedincontext = React.createContext();
export class Loggedincontext_provider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isloggedin: false,
            setisloggedin: this.setisloggedin,
            userloggedobj: {},
            setuserloggedobj: this.setuserloggedobj,
            spot_cats: [],
            authorizedspotcats: [],
            spot_packages: [],
            messageconversations: [],
            totalunreadmessages: '',
            adminmessagesinfo: {},
            notifications: {},
            systeminfocontext: {},
            setsysteminfocontext: this.setsysteminfocontext,
            setspot_cats: this.setspot_cats,
            setauthorizedspotcats: this.setauthorizedspotcats,
            setspot_packages: this.setspot_packages,
            setmessageconversations: this.setmessageconversations,
            settotalunreadmessages: this.settotalunreadmessages,
            setadminmessagesinfo: this.setadminmessagesinfo,
            setnotifications: this.setnotifications,
            notificationtoseenfunc: this.notificationtoseenfunc,
        };
    }
    setnotifications = (notificiationsset) => {
        this.setState({
            notifications: notificiationsset,
        });
    };
    setadminmessagesinfo = (adminmessagesinfod) => {
        this.setState({
            adminmessagesinfo: adminmessagesinfod,
        });
    };
    settotalunreadmessages = (counter) => {
        this.setState({
            totalunreadmessages: counter,
        });
    };
    setmessageconversations = (msgconv) => {
        this.setState({
            messageconversations: msgconv,
        });
    };
    setspot_packages = (spotpackages) => {
        this.setState({
            spot_packages: spotpackages,
        });
    };

    setauthorizedspotcats = (spotcats) => {
        this.setState({
            authorizedspotcats: spotcats,
        });
    };
    setsysteminfocontext = (spotcats) => {
        this.setState({
            systeminfocontext: spotcats,
        });
    };
    setspot_cats = (spotcats) => {
        this.setState({
            spot_cats: spotcats,
        });
    };

    setuserloggedobj = (userobj) => {
        this.setState({
            userloggedobj: userobj,
        });
    };
    setisloggedin = (loggstatus) => {
        if (loggstatus) {
            this.setState({
                isloggedin: loggstatus,
            });
        } else {
            this.setState({
                isloggedin: loggstatus,
            });
        }
    };
    notificationtoseenfunc = () => {
        if (this.state.notifications != undefined && this.state.notifications.notificationscounter != undefined && this.state.notifications.notificationscounter != 0) {
            var tempnotificationscontext = { ...this.state.notifications };
            tempnotificationscontext.notificationscounter = 0;

            this.setState({
                notifications: tempnotificationscontext,
            });

            axios({
                method: 'post',
                url: 'https://spotearned.com/generalall/notificationstoseen',
                // data: { searchinput: searchinputvalue },
                headers: axiosheaders,
            })
                .then((response) => {
                    console.log(response.data);
                    // setsearchresultsarray(response.data.users);
                    // setissearchfetching(false);
                    // setsearchresultsarr(response.data)
                    // setsearchpreloader(false)
                })
                .catch((error) => {
                    console.log(error);
                    // alert('Error In Searching');
                });
        }
    };

    render() {
        var notificationtoseenfunccontext = this.state.notificationtoseenfunc;
        var isloggedincontext = this.state.isloggedin;
        var setisloggedincontext = this.state.setisloggedin;
        var userloggedobjcontext = this.state.userloggedobj;
        var setuserloggedobjcontext = this.state.setuserloggedobj;
        var messageconversationscontext = this.state.messageconversations;
        var setmessageconversationscontext = this.state.setmessageconversations;
        var totalunreadmessagescontext = this.state.totalunreadmessages;
        var settotalunreadmessagescontext = this.state.settotalunreadmessages;
        var adminmessagesinfocontext = this.state.adminmessagesinfo;
        var setadminmessagesinfocontext = this.state.setadminmessagesinfo;
        var setspot_catscontext = this.state.setspot_cats;
        var spot_catscontext = this.state.spot_cats;
        var authorizedspotcatscontext = this.state.authorizedspotcats;
        var setauthorizedspotcatscontext = this.state.setauthorizedspotcats;
        var systeminfocontext = this.state.systeminfocontext;
        var setsysteminfocontext = this.state.setsysteminfocontext;

        var spot_packagescontext = this.state.spot_packages;
        var setspot_packagescontext = this.state.setspot_packages;
        var setnotificationscontext = this.state.setnotifications;
        var notificationscontext = this.state.notifications;
        return (
            <Loggedincontext.Provider
                value={{
                    isloggedincontext,
                    setisloggedincontext,
                    userloggedobjcontext,
                    setuserloggedobjcontext,
                    spot_catscontext,
                    setspot_catscontext,
                    spot_packagescontext,
                    setspot_packagescontext,
                    messageconversationscontext,
                    setmessageconversationscontext,
                    totalunreadmessagescontext,
                    settotalunreadmessagescontext,
                    adminmessagesinfocontext,
                    setadminmessagesinfocontext,
                    notificationscontext,
                    setnotificationscontext,
                    authorizedspotcatscontext,
                    setauthorizedspotcatscontext,
                    notificationtoseenfunccontext,
                    systeminfocontext,
                    setsysteminfocontext,
                }}
            >
                {this.props.children}
            </Loggedincontext.Provider>
        );
    }
}

export const Loggedincontext_consumer = Loggedincontext.Consumer;
