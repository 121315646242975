import React, { Component, useState } from 'react';
export const GeneralContext = React.createContext();

export const GeneralContext_provider = (props) => {
    const [galleryfilterparamsContext, setgalleryfilterparamsContext] = useState({
        sortby: 'none',
        subcategories: [],
        categories: [],
        gender: 'both',
    });
    const [galleryTypeContext, setgalleryTypeContext] = useState('');

    return (
        <GeneralContext.Provider
            value={{
                galleryfilterparamsContext,
                setgalleryfilterparamsContext,
                galleryTypeContext,
                setgalleryTypeContext,
            }}
        >
            {props.children}
        </GeneralContext.Provider>
    );
};

export const GeneralContext_consumer = GeneralContext.Consumer;
