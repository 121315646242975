import  axios  from 'axios';
const axiosheaders = { 
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };
  export const FetchUserProfile = (axiosdata) => {
   const axiosfetch = axios({
        method: 'post',
        url: 'https://spotearned.com/surfer/getuserprofileinfo',
        headers:axiosheaders,
        data:axiosdata
    })
      return axiosfetch;
  }
