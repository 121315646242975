import axios from 'axios';
const axiosheaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};
export const fetchuserportfolio_API = (axiosdata) => {
    // params : {userprofilelink}
    const axiosfetch = axios({
        method: 'get',
        url: 'https://spotearned.com/surfer/getuserportfolio',
        headers: axiosheaders,
        params: axiosdata,
    });
    return axiosfetch;
};
export const SendRequest_API = (axiosdata) => {
    // params : {userprofilelink}
    const axiosfetch = axios({
        method: 'post',
        url: 'https://spotearned.com/surfer/sendreqquotesurfer',
        headers: axiosheaders,
        params: axiosdata,
    });
    return axiosfetch;
};
