import React from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
function Arrow({ children, disabled, onClick }: { children: React.ReactNode, disabled: boolean, onClick: VoidFunction }) {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            style={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                opacity: disabled ? '0' : '1',
                userSelect: 'none',
                width: '100%',
                height: 50,
                position: 'absolute',
                top: 0,
                bottom: 0,
                margin: 'auto',
            }}
        >
            {children}
        </button>
    );
}

export function LeftArrow() {
    const { isFirstItemVisible, scrollNext, scrollPrev, visibleItemsWithoutSeparators, initComplete } = React.useContext(VisibilityContext);
    const [disabled, setDisabled] = React.useState(!initComplete || (initComplete && isFirstItemVisible));
    React.useEffect(() => {
        // NOTE: detect if whole component visible
        if (visibleItemsWithoutSeparators.length) {
            setDisabled(isFirstItemVisible);
        }
    }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

    return (
        <Arrow
            disabled={disabled}
            onClick={() => {
                scrollPrev();
            }}
            style={[]}
        >
            <div
                class="d-flex align-items-center justify-content-center text-white text-secondaryhover"
                style={{ position: 'absolute', left: -30, width: '5vh', height: '5vh', borderRadius: '50%', zIndex: 1000 }}
            >
                <i class="">
                    <BsChevronLeft />
                </i>
            </div>
        </Arrow>
    );
}

export function RightArrow() {
    const { isLastItemVisible, scrollNext, scrollPrev, visibleItemsWithoutSeparators } = React.useContext(VisibilityContext);
    const [disabled, setDisabled] = React.useState(!visibleItemsWithoutSeparators.length && isLastItemVisible);
    React.useEffect(() => {
        if (visibleItemsWithoutSeparators.length) {
            setDisabled(isLastItemVisible);
        }
    }, [isLastItemVisible, visibleItemsWithoutSeparators]);

    return (
        <Arrow
            disabled={disabled}
            onClick={() => {
                scrollNext();
            }}
        >
            <div class="d-flex align-items-center justify-content-center text-white text-secondaryhover" style={{ position: 'absolute', right: -30, width: '5vh', height: '5vh', borderRadius: '50%' }}>
                <i class="">
                    <BsChevronRight />
                </i>
            </div>
        </Arrow>
    );
}
export function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
        ev.stopPropagation();
        return;
    }

    if (ev.deltaY < 0) {
        apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
        apiObj.scrollPrev();
    }
}
