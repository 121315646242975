import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, browserHistory, Redirect } from 'react-router-dom';
import { useQuery, useQueryClient, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import Userprofile from './components/Userprofile/Userprofile';
import { Loggedincontext } from './Loggedincontext.js';
import { DashboardRoutedispcontext_provider } from './components/Dashboard/DashboardRoutedispcontext';
import { GeneralContext_provider } from './GeneralContext';
import { Routedispatcherprovider } from './Routedispatcher';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { IKImage, IKContext, IKUpload } from 'imagekitio-react';
import 'react-notifications/lib/notifications.css';
import axios from 'axios';
import './trans.css';
import './components/Dashboard/Generalfiles/CSS_GENERAL/forms.css';
import Projectprofile from './components/Projectprofile/Projectprofile';
// import './components/Generalfiles/bootstrap.css';
const Websiterouter = React.lazy(() => import('./components/Website/Websiterouter'));
const Dashboardrouter = React.lazy(() => import('./components/Dashboard/Dashboardrouter'));
const queryClient = new QueryClient();
const App = (props) => {
    const {
        isloggedincontext,
        setisloggedincontext,
        userloggedobjcontext,
        setuserloggedobjcontext,
        spot_catscontext,
        setauthorizedspotcatscontext,
        setspot_catscontext,
        spot_packagescontext,
        setspot_packagescontext,
        setmessageconversationscontext,
        settotalunreadmessagescontext,
        setadminmessagesinfocontext,
        setnotificationscontext,
        systeminfocontext,
        setsysteminfocontext,
    } = React.useContext(Loggedincontext);
    // const {routedispatchercontext}= React.useContext(Routedispatcher)
    const [preloaderpercent, setpreloaderpercent] = useState(0);
    const [preloaderexists, setpreloaderexists] = useState(true);
    const authchecker = () => {
        const axiosheaders = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
        var c = 0;
        var preloaderinterval = setInterval(function () {
            setpreloaderpercent(c);

            if (c >= 100) {
                clearInterval(preloaderinterval);
            } else {
                c++;
            }
        }, 50);
        //not prod
        axios.defaults.withCredentials = true;
        axios({
            method: 'post',
            url: 'https://spotearned.com/authcheck',
            headers: axiosheaders,
        })
            .then((response) => {
                if (response.data.status) {
                    setisloggedincontext(true);
                    setuserloggedobjcontext(response.data.userobj);
                    setmessageconversationscontext(response.data.messageconversations);
                    settotalunreadmessagescontext(response.data.totalunseenmessages);
                    setadminmessagesinfocontext(response.data.adminmessagesinfo);
                    setnotificationscontext(response.data.notifications);
                    setauthorizedspotcatscontext(response.data.authorizedcats);

                    clearInterval(preloaderinterval);
                } else {
                    setisloggedincontext(false);
                }
                setsysteminfocontext(response.data.sys);
                setspot_catscontext(response.data.categories);
                setspot_packagescontext(response.data.packages);
                setpreloaderexists(false);
            })
            .catch((error) => {
                console.log(error);
                setisloggedincontext(false);
                NotificationManager.error('', 'Error.');
            });
    };

    let [key, setKey] = useState();

    useEffect(() => {
        authchecker();
        setKey(Math.random());
    }, []);
    return (
        <QueryClientProvider client={queryClient}>
            <IKContext
                publicKey="public_/a8nHvl+r1OJR5gBbEjY/p17mEc="
                urlEndpoint="https://ik.imagekit.io/8tdult3h6"
                transformationPosition="path"
                //   authenticationEndpoint="http://www.tab.com/auth"
            >
                <Router>
                    <GeneralContext_provider>
                        <Routedispatcherprovider>
                            <div style={{ height: '100%', width: '100%' }}>
                                {preloaderexists && (
                                    <div style={{ height: '100%', width: '100%', position: 'absolute' }} className={preloaderexists ? 'loading-page-container' : 'preloaderfadeOut'}>
                                        <div class="loading-page" style={{ width: preloaderpercent + '%' }}>
                                            <div class="counter">
                                                <h1>{preloaderpercent}</h1>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {!preloaderexists && (
                                    <Suspense
                                        fallback={
                                            <div
                                                style={{
                                                    height: '100%',
                                                    width: '100%',
                                                    position: 'absolute',
                                                }}
                                            >
                                                <div className={'loading-page-container'}>
                                                    <div class="loading-page" style={{ width: preloaderpercent + '%' }}>
                                                        <div class="counter">
                                                            <h1>{preloaderpercent}</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    >
                                        <Route
                                            render={({ location, match }) => {
                                                return (
                                                    <TransitionGroup>
                                                        <CSSTransition in={match != null} key={location.key} timeout={300} classNames="page" unmountOnExit>
                                                            <Switch key={location.key} location={location}>
                                                                <Route exact path="/" render={() => <Redirect to="/home" />} />
                                                                <Route
                                                                    path="/home"
                                                                    render={(props) => {
                                                                        return <Websiterouter />;
                                                                    }}
                                                                />

                                                                {/* <Route
                                                                    path="/:userprofilelink/profile"
                                                                    render={(props) => {
                                                                        if (isloggedincontext == true) {
                                                                            return <Redirect to={`/dashboard/${props.match.params.userprofilelink}/profile`} />;
                                                                        } else {
                                                                            return (
                                                                                <DashboardRoutedispcontext_provider>
                                                                                    <Userprofile />
                                                                                </DashboardRoutedispcontext_provider>
                                                                            );
                                                                        }
                                                                    }}
                                                                /> */}

                                                                <Route
                                                                    path="/dashboard"
                                                                    render={(props) => {
                                                                        if (isloggedincontext == true) {
                                                                            return <Dashboardrouter />;
                                                                        }
                                                                    }}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path="/:userprofilelink"
                                                                    render={(props) => {
                                                                        return <Redirect to={'/' + props.match.params.userprofilelink + '/all' + '/all'} />;
                                                                    }}
                                                                />
                                                                <Route
                                                                    path="/:userprofilelink/:catname/:projectname"
                                                                    render={(props) => {
                                                                        return <Projectprofile />;
                                                                    }}
                                                                />
                                                            </Switch>
                                                        </CSSTransition>
                                                    </TransitionGroup>
                                                );
                                            }}
                                        />
                                    </Suspense>
                                )}
                            </div>
                        </Routedispatcherprovider>
                    </GeneralContext_provider>
                </Router>
                <ReactQueryDevtools initialIsOpen />
                <NotificationContainer />
            </IKContext>
        </QueryClientProvider>
    );
};

export default App;
