import React, { useState, useRef, useCallback, useEffect, useContext } from 'react';
import tabsstyles from '../Website/Generalfiles/CSS_GENERAL/tabs.module.css';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { LeftArrow, RightArrow } from './Arrows.js';
import { useParams, useHistory } from 'react-router-dom';
import { BiMessageSquareEdit } from 'react-icons/bi';
import { BsTelephone, BsGlobe, BsCheck2All } from 'react-icons/bs';
import { FaFacebookF, FaVimeoV, FaInstagram } from 'react-icons/fa';
import { MdOutlineAlternateEmail } from 'react-icons/md';
import { TbWorld } from 'react-icons/tb';
import styles from './Projectprofile.module.css';
import generalstyles from '../Website/Generalfiles/CSS_GENERAL/websitegeneral.module.css';
import { IKImage } from 'imagekitio-react';
import { Box, Tab, Tabs } from '@mui/material';
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import pager from 'lightgallery/plugins/pager';
import fullScreen from 'lightgallery/plugins/fullscreen';
import autoplay from 'lightgallery/plugins/autoplay';
import lgVideo from 'lightgallery/plugins/video';
import video from 'lightgallery/plugins/video';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-share.css';
import 'lightgallery/css/lg-autoplay.css';
import 'lightgallery/css/lg-pager.css';
import 'lightgallery/css/lg-video.css';
import { Button, Modal } from 'react-bootstrap';
import lightgallerystyles from '../Dashboard/Generalfiles/CSS_GENERAL/lightgallery.module.css';
import { fetchuserportfolio_API, SendRequest_API } from '../Projectprofile/Projectprofile_API';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { NotificationManager } from 'react-notifications';
import { imagesserverbaselink } from '../../Env_Variables';
import TextareaAutosize from 'react-textarea-autosize';
import PhoneInput from 'react-phone-input-2';
import '../Website/Apply/Phonenumberinput.css';
import { Loggedincontext } from '../../Loggedincontext';
import loginstyles from '../Website/Login/login.module.css';
import axios from 'axios';
import { CircularProgress } from 'react-cssfx-loading';

const Home = (props) => {
    const history = useHistory();
    const queryClient = useQueryClient();
    const { isloggedincontext, userloggedobjcontext, setuserloggedobjcontext } = useContext(Loggedincontext);
    const { userprofilelink, catname, projectname } = useParams();
    const lightGallery = useRef();
    const onInit = useCallback((detail) => {
        if (detail) {
            lightGallery.current = detail.instance;
        }
    }, []);
    const [show, setShow] = useState(false);
    const [checked, setChecked] = useState(false);
    const [currentchecked, setcurrentchecked] = useState('');
    const [currentcategory, setcurrentcategory] = useState('');
    const [projectsarray, setprojectsarray] = useState([]);
    const [projectindex, setprojectindex] = useState('');
    const [activeprofiletab, setactiveprofiletab] = useState('contactinfo');
    const [userprofileimg, setuserprofileimg] = useState('');
    const [isupdatingprofileimg, setisupdatingprofileimg] = useState(false);
    const [coverimg, setcoverimg] = useState('');
    const [isupdatingcoverimg, setisupdatingcoverimg] = useState(false);
    const [buttondisabled, setbuttondisabled] = useState(false);
    const [sendquotationpayload, setsendquotationpayload] = useState({
        quotetouseridmodal: '',
        sreqname: '',
        sreqemail: '',
        sreqmobile: '',
        sreqbody: '',
    });
    const [tabIndex, setTabIndex] = useState(0);
    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };
    const SendRequestMutation = useMutation('SendRequest_API', {
        mutationFn: SendRequest_API,
        onMutate: (variables) => {},
        onError: (error, variables, context) => {
            NotificationManager.error('Error', 'Error In Sending quotation request.');
        },
        onSuccess: (data, variables, context) => {
            if (data.data.status) {
                setsendquotationpayload({
                    quotetouseridmodal: '',
                    sreqname: '',
                    sreqemail: '',
                    sreqmobile: '',
                    sreqbody: '',
                });
                setShow(false);
                NotificationManager.success('', 'Quotation send successfully');
            } else {
                NotificationManager.warning('', data.data.reason);
            }
        },
    });
    const fetchuserportfolio_Query = useQuery(
        ['fetchuserportfolio_API' + userprofilelink],
        () =>
            fetchuserportfolio_API({
                userprofilelink: userprofilelink,
            }),
        {
            keepPreviousData: true,
            staleTime: Infinity,
        },
    );
    useEffect(() => {
        if (fetchuserportfolio_Query.isSuccess && fetchuserportfolio_Query.data.data.status) {
            var tempprojectsarray = [];
            document.title = fetchuserportfolio_Query?.data?.data?.mainuserinfo?.fullname;
            if (fetchuserportfolio_Query?.data?.data?.maincatsinsproj.length != 0) {
                fetchuserportfolio_Query?.data?.data?.maincatsinsproj.forEach((item, index) => {
                    item.projects.forEach(function (projitem, projindex) {
                        if (projitem.projcontents.length != 0) {
                            tempprojectsarray.push(projitem);
                        }
                    });
                });
            }
            if (catname != 'all') {
                var tempprojectsarray = [];
                fetchuserportfolio_Query?.data?.data?.maincatsinsproj.forEach((item, index) => {
                    var catnametemp = item.catname.replace(/ /g, '');
                    catnametemp = catnametemp.toLowerCase();
                    var tempcatnameparam = catname.replace(/ /g, '').toLowerCase();
                    if (catnametemp == tempcatnameparam) {
                        setcurrentcategory(item);
                        item.projects.forEach(function (projitem, projindex) {
                            if (projitem.projcontents.length != 0) {
                                tempprojectsarray.push(projitem);
                            }
                        });
                    }
                });
            }
            if (projectname != 'all') {
                tempprojectsarray.forEach(function (projitem, projindex) {
                    if (projitem.name == projectname) {
                        setprojectindex(projindex);
                    }
                });
            }
            setprojectsarray(tempprojectsarray);
        }
    }, [fetchuserportfolio_Query.isSuccess]);
    const favcontentclick = (name) => {
        history.push('/' + userprofilelink + '/all' + '/' + name);
    };
    const getusername = () => {
        if (fetchuserportfolio_Query.isSuccess == true && fetchuserportfolio_Query.isLoading == false && fetchuserportfolio_Query.data.data.status == true) {
            if (fetchuserportfolio_Query?.data?.data?.mainuserinfo.type == 'user') {
                return fetchuserportfolio_Query?.data?.data?.mainuserinfo.fullname;
            } else {
                return fetchuserportfolio_Query?.data?.data?.mainuserinfo.agencyname;
            }
        } else {
        }
    };
    function ProjectsCard({ title, itemId, item, index }: { title: string, itemId: string }) {
        const visibilityProjectsCard = React.useContext(VisibilityContext);
        const visibleProjectsCard = visibilityProjectsCard.isItemVisible(itemId);
        return (
            <div
                class={
                    projectindex !== '' && item.id === projectsarray[projectindex].id
                        ? `${styles.scrollhorizontalcard} ${styles.scrollhorizontalcardactive}` + ' col-lg-12 m-0 w-100 pb-2 mr-2 '
                        : `${styles.scrollhorizontalcard}` + ' col-lg-12 m-0 w-100 pb-2 mr-2 w-100'
                }
                style={{ zIndex: 100 }}
                onClick={() => {
                    setcurrentchecked(index);
                    history.push('/' + userprofilelink + '/' + catname + '/' + item.name);
                }}
            >
                <p class="text-capitalize text-white text-secondaryhover m-0 p-0" style={{ fontWeight: item.route == currentchecked ? 600 : 500 }}>
                    {item.name}
                </p>
            </div>
        );
    }

    function EndorsementCircleCard({ title, itemId, item, index }: { title: string, itemId: string }) {
        const visibilityProjectsCard = React.useContext(VisibilityContext);
        const visibleProjectsCard = visibilityProjectsCard.isItemVisible(itemId);
        return (
            <div
                class="col-lg-12 d-flex"
                style={{ cursor: 'pointer', zIndex: 100 }}
                onClick={() => {
                    window.open(window.location.origin + '/' + item.profilelink);
                }}
            >
                <div class="row m-0 w-100 d-flex flex-row cursor-pointer text-white ">
                    <IKImage path={item.profileurl} style={{ maxWidth: '50px', maxHeight: '50px', objectFit: 'contain', borderRadius: 100 }} />
                    <div class="col-8 d-flex align-items-center">
                        <p class="m-0 p-0 text-capitalize text-secondaryhover">{item.fname + ' ' + item.lname}</p>
                    </div>
                </div>
            </div>
        );
    }

    const updatecoverphoto = () => {
        if (coverimg.length != 0) {
            setisupdatingcoverimg(true);
            setbuttondisabled(true);
            const axiosheaders = {
                'Content-Type': 'multipart/form-data',
            };
            const formData = new FormData();
            formData.append('coverimg', coverimg);
            axios({
                method: 'post',
                url: 'https://spotearned.com/generalall/updatecoverimg',
                data: formData,
                headers: axiosheaders,
            })
                .then((response) => {
                    console.log(response.data);
                    setbuttondisabled(false);
                    setisupdatingcoverimg(false);
                    setcoverimg('');
                    if (response.data.status) {
                        var temparray = queryClient.getQueryData('fetchuserportfolio_API' + userprofilelink);
                        temparray.data.mainuserinfo.coverurl = response.data.newimagepath;
                        queryClient.setQueryData('fetchuserportfolio_API' + userprofilelink, temparray);
                        NotificationManager.success('', 'Cover Photo Updated.');
                        window.location.reload();
                    } else {
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setcoverimg('');
                    setisupdatingcoverimg(false);
                    setbuttondisabled(false);
                    NotificationManager.error('Error', 'Error In Updating Cover Photo.');
                });
        }
    };
    const updateprofileimg = () => {
        if (userprofileimg.length != 0) {
            setbuttondisabled(true);
            setisupdatingprofileimg(true);
            const axiosheaders = {
                'Content-Type': 'multipart/form-data',
            };
            const formData = new FormData();
            formData.append('profileimg', userprofileimg);
            axios({
                method: 'post',
                url: 'https://spotearned.com/generalall/updateprofileimg',
                data: formData,
                headers: axiosheaders,
            })
                .then((response) => {
                    console.log(response.data);
                    setbuttondisabled(false);
                    setisupdatingprofileimg(false);
                    if (response.data.status) {
                        var userloggedobjcontexttemp = { ...userloggedobjcontext };
                        userloggedobjcontexttemp.userprofile.profileurl = response.data.newimagepath;
                        setuserloggedobjcontext({ ...userloggedobjcontexttemp });
                        window.location.reload();
                        NotificationManager.success('', 'Profile Photo Updated.');
                    } else {
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setbuttondisabled(false);
                    setisupdatingprofileimg(false);
                    NotificationManager.error('Error', 'Error In Updating Profile Image.');
                });
        }
    };

    return (
        <div class={`${styles.scrollcont} ${generalstyles.websitebodyclass}` + ' row m-0 w-100 '} style={{ overflowX: 'hidden' }}>
            {fetchuserportfolio_Query.isSuccess && !fetchuserportfolio_Query.isLoading && fetchuserportfolio_Query.data.data.status && (
                <div class="col-lg-12 p-0">
                    <div style={{ width: '100%', height: '350px', position: 'relative' }} class="w-100 m-0">
                        {fetchuserportfolio_Query?.data?.data?.mainuserinfo?.coverurl != null && coverimg.length == 0 && (
                            <IKImage path={fetchuserportfolio_Query?.data?.data?.mainuserinfo?.coverurl} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        )}
                        {fetchuserportfolio_Query?.data?.data?.mainuserinfo?.coverurl == null && coverimg.length == 0 && (
                            <div style={{ width: '100%', height: '100%', borderBottom: '1px solid white' }} class="d-flex align-items-center justify-content-center">
                                <img src="https://spotearned.com/storage/images/nocontent.png" style={{ width: '30%', height: '30%', objectFit: 'contain' }} />
                            </div>
                        )}
                        {coverimg.length != 0 && <img src={URL.createObjectURL(coverimg)} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />}
                        {userloggedobjcontext.id == fetchuserportfolio_Query?.data?.data?.mainuserinfo?.id && (
                            <div style={{ position: 'absolute', top: 20, right: 20 }} class="d-flex align-items-center">
                                <input
                                    type="file"
                                    accept="image/*"
                                    name="coverimageinput"
                                    id="coverimageinput"
                                    class={'d-none'}
                                    onChange={(event) => {
                                        if (event.target.files[0] != undefined) {
                                            setcoverimg(event.target.files[0]);
                                        }
                                    }}
                                />
                                <div className="d-flex align-items-center">
                                    <label
                                        for={'coverimageinput'}
                                        class={'p-0 m-0 d-flex align-items-center justify-content-center cursor-pointer pl-3 pr-3 bg-secondaryhover'}
                                        style={{ background: '#1b1b1b', borderRadius: '100px', fontSize: '13px', height: '40px' }}
                                    >
                                        Change cover photo
                                    </label>
                                    {coverimg.length != 0 && (
                                        <button
                                            class={'m-0 p-0 ml-2 d-flex align-items-center justify-content-center cursor-pointer text-white bg-secondaryhover'}
                                            disabled={buttondisabled}
                                            onClick={updatecoverphoto}
                                            style={{ background: '#1b1b1b', borderRadius: '100px', fontSize: '13px', height: '40px', width: '70px' }}
                                        >
                                            {isupdatingcoverimg ? <CircularProgress color={'white'} width="18px" height="18px" /> : <p class="m-0 p-0">Save</p>}
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div class="row w-100 m-0 d-flex justify-content-center pl-md-3 pr-md-3 pl-md-2 pr-md-2">
                        <div class="col-md-12 d-none d-md-flex justify-content-center flex-column" style={{ position: 'relative', minHeight: '100px' }}>
                            <div style={{ width: '180px', height: '180px', marginTop: '-110px', borderRadius: '100%', border: '4px solid white', position: 'relative' }} class="ml-auto mr-auto">
                                {userprofileimg.length == 0 && (
                                    <IKImage
                                        path={fetchuserportfolio_Query?.data?.data?.mainuserinfo?.profileurl}
                                        style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '100%' }}
                                    />
                                )}
                                {userprofileimg.length != 0 && userloggedobjcontext.id == fetchuserportfolio_Query?.data?.data?.mainuserinfo?.id && (
                                    <img src={URL.createObjectURL(userprofileimg)} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '100%' }} />
                                )}
                                {userloggedobjcontext.id == fetchuserportfolio_Query?.data?.data?.mainuserinfo?.id && (
                                    <div class="w-100">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            name="profileimginput"
                                            id="profileimginput"
                                            class={'d-none'}
                                            onChange={(event) => {
                                                if (event.target.files[0] != undefined) {
                                                    setuserprofileimg(event.target.files[0]);
                                                }
                                            }}
                                        />
                                        {userprofileimg.length == 0 && (
                                            <label
                                                for={'profileimginput'}
                                                class="d-flex justify-content-center align-items-center bg-secondaryhover"
                                                style={{ position: 'absolute', top: 5, right: 5, background: '#1b1b1b', width: '4.5vh', height: '4.5vh', borderRadius: '100%' }}
                                            >
                                                <BiMessageSquareEdit class="text-white" />
                                            </label>
                                        )}
                                        {userprofileimg.length != 0 && (
                                            <button
                                                class="d-flex justify-content-center align-items-center bg-secondaryhover"
                                                style={{ position: 'absolute', top: 5, right: 5, background: '#1b1b1b', width: '4.5vh', height: '4.5vh', borderRadius: '100%' }}
                                                onClick={updateprofileimg}
                                                disabled={buttondisabled}
                                            >
                                                {isupdatingprofileimg ? <CircularProgress color={'#fff'} width="18px" height="18px" /> : <BsCheck2All class="text-white" />}
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div class="row m-0 w-100 d-flex justify-content-center mt-4 mb-2">
                                {fetchuserportfolio_Query?.data?.data?.mainuserinfo?.fblink != undefined && (
                                    <a href={fetchuserportfolio_Query?.data?.data?.mainuserinfo?.fblink} target="_blank" class={`${styles.socialmediaiconcontainer}` + ' ml-2 mr-2 '}>
                                        <i class="h-100 d-flex align-items-center justify-content-center">
                                            <FaFacebookF size={15} />
                                        </i>
                                    </a>
                                )}
                                {fetchuserportfolio_Query?.data?.data?.mainuserinfo?.instlink != undefined && (
                                    <a href={fetchuserportfolio_Query?.data?.data?.mainuserinfo?.instlink} target="_blank" class={`${styles.socialmediaiconcontainer}` + ' ml-2 mr-2 '}>
                                        <i class="h-100 d-flex align-items-center justify-content-center">
                                            <FaInstagram size={16} />
                                        </i>
                                    </a>
                                )}
                                {fetchuserportfolio_Query?.data?.data?.mainuserinfo?.youtlink != undefined && (
                                    <a href={fetchuserportfolio_Query?.data?.data?.mainuserinfo?.youtlink} target="_blank" class={`${styles.socialmediaiconcontainer}` + ' ml-2 mr-2 '}>
                                        <i class="h-100 d-flex align-items-center justify-content-center">
                                            <FaVimeoV size={15} />
                                        </i>
                                    </a>
                                )}
                                {fetchuserportfolio_Query?.data?.data?.mainuserinfo?.websitelink != undefined && (
                                    <a href={fetchuserportfolio_Query?.data?.data?.mainuserinfo?.websitelink} target="_blank" class={`${styles.socialmediaiconcontainer}` + ' ml-2 mr-2 '}>
                                        <i class="h-100 d-flex align-items-center justify-content-center">
                                            <TbWorld size={18} />
                                        </i>
                                    </a>
                                )}
                            </div>
                        </div>
                        <div class="col-lg-5 justify-content-start mt-3 pl-5 pl-1025-3 pr-2 p-md-0 mb-sm-4">
                            <div class="row m-0 mb-2 w-100">
                                <div class="col-lg-12 p-0">
                                    <div class={styles.header + ' m-0 w-100 row '}>
                                        <div class="col-lg-6 col-md-6 col-sm-12 p-0 d-flex">
                                            <p style={{ fontSize: '18px' }} class={' m-0 p-0 fw-600 text-capitalize text-white w-100 '}>
                                                {getusername()}
                                            </p>
                                        </div>
                                        <div class="col-lg-6 col-md-6 d-sm-none p-0 d-flex justify-content-end">
                                            <p
                                                class={' text-secondary text-whitehover m-0 p-0 fw-500 text-uppercase '}
                                                onClick={() => {
                                                    setShow(true);
                                                }}
                                                style={{ letterSpacing: '1px' }}
                                            >
                                                Request quotation
                                            </p>
                                        </div>
                                        <div class="col-sm-12 d-none d-md-none d-sm-flex p-0 justify-content-center" style={{ marginTop: 40 }}>
                                            <button
                                                class={' text-secondary text-whitehover m-0 p-0 fw-500 text-uppercase '}
                                                onClick={() => {
                                                    setShow(true);
                                                }}
                                                style={{ letterSpacing: '1px' }}
                                            >
                                                Request quotation
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 p-0 d-flex justify-content-start mt-4">
                                    <p style={{ color: '#bfbfbf', lineHeight: '21px', fontSize: '16px' }} class="d-flex justify-content-start p-0 m-0">
                                        {fetchuserportfolio_Query?.data?.data?.mainuserinfo?.biography}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 d-flex d-md-none justify-content-center p-0" style={{ position: 'relative' }}>
                            <div class={styles.profileimage}>
                                {fetchuserportfolio_Query?.data?.data?.mainuserinfo?.profileurl != null && userprofileimg.length == 0 && (
                                    <IKImage
                                        path={fetchuserportfolio_Query?.data?.data?.mainuserinfo?.profileurl}
                                        style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '100%' }}
                                    />
                                )}
                                {fetchuserportfolio_Query?.data?.data?.mainuserinfo?.profileurl == null && userprofileimg.length == 0 && (
                                    <div style={{ width: '100%', height: '100%', background: '#1b1b1b', borderRadius: '100%' }} class="d-flex align-items-center justify-content-center">
                                        <img src="https://spotearned.com/storage/images/nocontent.png" style={{ width: '90%', height: '90%', objectFit: 'contain' }} />
                                    </div>
                                )}
                                {userprofileimg.length != 0 && userloggedobjcontext.id == fetchuserportfolio_Query?.data?.data?.mainuserinfo?.id && (
                                    <img src={URL.createObjectURL(userprofileimg)} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '100%' }} />
                                )}
                                {userloggedobjcontext.id == fetchuserportfolio_Query?.data?.data?.mainuserinfo?.id && (
                                    <div class="w-100">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            name="profileimginput"
                                            id="profileimginput"
                                            class={'d-none'}
                                            onChange={(event) => {
                                                if (event.target.files[0] != undefined) {
                                                    setuserprofileimg(event.target.files[0]);
                                                }
                                            }}
                                        />
                                        {userprofileimg.length == 0 && (
                                            <label
                                                for={'profileimginput'}
                                                class="d-flex justify-content-center align-items-center bg-secondaryhover cursor-pointer"
                                                style={{ position: 'absolute', top: 5, right: 5, background: '#1b1b1b', width: '4.5vh', height: '4.5vh', borderRadius: '100%' }}
                                            >
                                                <BiMessageSquareEdit class="text-white" />
                                            </label>
                                        )}
                                        {userprofileimg.length != 0 && (
                                            <button
                                                class="d-flex justify-content-center align-items-center bg-secondaryhover"
                                                style={{ position: 'absolute', top: 5, right: 5, background: '#1b1b1b', width: '4.5vh', height: '4.5vh', borderRadius: '100%' }}
                                                onClick={updateprofileimg}
                                                disabled={buttondisabled}
                                            >
                                                {isupdatingprofileimg ? <CircularProgress color={'#fff'} width="18px" height="18px" /> : <BsCheck2All class="text-white" />}
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div class="col-lg-12 p-0" style={{ marginTop: 120 }}>
                                <div class="row m-0 w-100 d-flex justify-content-center">
                                    {fetchuserportfolio_Query?.data?.data?.mainuserinfo?.fblink != undefined && (
                                        <a href={fetchuserportfolio_Query?.data?.data?.mainuserinfo?.fblink} target="_blank" class={`${styles.socialmediaiconcontainer}` + ' ml-2 mr-2 '}>
                                            <i class="h-100 d-flex align-items-center justify-content-center">
                                                <FaFacebookF size={15} />
                                            </i>
                                        </a>
                                    )}
                                    {fetchuserportfolio_Query?.data?.data?.mainuserinfo?.instlink != undefined && (
                                        <a href={fetchuserportfolio_Query?.data?.data?.mainuserinfo?.instlink} target="_blank" class={`${styles.socialmediaiconcontainer}` + ' ml-2 mr-2 '}>
                                            <i class="h-100 d-flex align-items-center justify-content-center">
                                                <FaInstagram size={16} />
                                            </i>
                                        </a>
                                    )}
                                    {fetchuserportfolio_Query?.data?.data?.mainuserinfo?.youtlink != undefined && (
                                        <a href={fetchuserportfolio_Query?.data?.data?.mainuserinfo?.youtlink} target="_blank" class={`${styles.socialmediaiconcontainer}` + ' ml-2 mr-2 '}>
                                            <i class="h-100 d-flex align-items-center justify-content-center">
                                                <FaVimeoV size={15} />
                                            </i>
                                        </a>
                                    )}
                                    {fetchuserportfolio_Query?.data?.data?.mainuserinfo?.websitelink != undefined && (
                                        <a href={fetchuserportfolio_Query?.data?.data?.mainuserinfo?.websitelink} target="_blank" class={`${styles.socialmediaiconcontainer}` + ' ml-2 mr-2 '}>
                                            <i class="h-100 d-flex align-items-center justify-content-center">
                                                <TbWorld size={18} />
                                            </i>
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 justify-content-start pl-0 pr-5 pr-1025-3 pl-md-3 pr-md-0 mt-1">
                            <div class={' row m-0 w-100 mb-2 '}>
                                <Tabs
                                    value={tabIndex}
                                    onChange={handleTabChange}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    TabIndicatorProps={{
                                        style: {
                                            background: '#ffbc00',
                                            height: '4px',
                                        },
                                    }}
                                    sx={{
                                        '& button': {
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                            marginRight: '30px',
                                            color: '#fff',
                                            fontFamily: 'Montserrat',
                                            fontSize: '15px',
                                            fontWeight: 600,
                                            transition: '.3s',
                                        },
                                        '& button:hover': {
                                            color: '#ffbc00',
                                            transition: '.3s',
                                        },
                                        '& button.Mui-selected': {
                                            color: '#ffbc00',
                                            transition: '.3s',
                                        },
                                    }}
                                >
                                    <Tab label="Contact Information" />
                                    <Tab label="Endorsements" />
                                    <Tab label="Circle" />
                                    <Tab label="Payment Methods" />
                                    <Tab label="Skills" />
                                </Tabs>
                                <div
                                    className="w-sm-100"
                                    style={{
                                        width: '95%',
                                        background: '#eee',
                                        height: 1,
                                    }}
                                ></div>
                                {tabIndex === 0 && (
                                    <div class="col-lg-12 p-0 mt-1">
                                        <div class="row m-0 w-100">
                                            <div class={' col-xl-6 col-lg-6 col-md-6 font-15 fw-500 d-flex align-items-center mb-md-3 mt-3 pl-1 pr-1 mb-2'}>
                                                <div class={styles.icon_cont + ' mr-1 mr-sm-2 mb-auto '}>
                                                    <i class="h-100 pl-2 pr-2 d-flex align-items-center justify-content-center" style={{ color: '#bfbfbf' }}>
                                                        <BsGlobe />
                                                    </i>
                                                </div>
                                                <div class="col-lg-10 d-flex flex-column p-0">
                                                    <p class="m-0 p-0 fw-500 font-14 text-capitalize text-white mb-sm-1">country</p>
                                                    <p class={' m-0 p-0 txt-secondary fw-600 font-16 text-capitalize wordbreak '}>{fetchuserportfolio_Query?.data?.data?.mainuserinfo?.country}</p>
                                                </div>
                                            </div>
                                            <div class={' col-xl-6 col-lg-6 col-md-6 font-15 fw-500 d-flex align-items-center mb-md-3 mt-3 pl-1 pr-1 mb-2'}>
                                                <div class={styles.icon_cont + ' mr-1 mr-sm-2 mb-auto '} style={{ color: '#bfbfbf' }}>
                                                    <i class="h-100 pl-2 pr-2 d-flex align-items-center justify-content-center">
                                                        <BsTelephone />
                                                    </i>
                                                </div>
                                                <div class="col-lg-10 d-flex flex-column p-0">
                                                    <p class="m-0 p-0 fw-500 font-14 text-capitalize text-white mb-sm-1">phone</p>
                                                    <p class={' m-0 p-0 txt-secondary fw-600 font-16 text-capitalize wordbreak '}>{fetchuserportfolio_Query?.data?.data?.mainuserinfo?.mobile}</p>
                                                </div>
                                            </div>
                                            <div class={' col-xl-12 col-lg-6 col-md-6 font-15 fw-500 d-flex align-items-center mb-md-3 pl-1 pr-1 mb-2 mt-3'}>
                                                <div class={styles.icon_cont + ' mr-1 mr-sm-2 mb-auto '} style={{ color: '#bfbfbf' }}>
                                                    <i class="h-100 pl-2 pr-2 d-flex align-items-center justify-content-center">
                                                        <MdOutlineAlternateEmail size={18} />
                                                    </i>
                                                </div>
                                                <div class="col-lg-10 d-flex flex-column p-0">
                                                    <p class="m-0 p-0 fw-500 font-14 text-capitalize text-white mb-sm-1">Email</p>
                                                    <p class={' col-lg-10 col-sm-9 m-0 p-0 txt-secondary fw-600 font-16 wordbreak '}>{fetchuserportfolio_Query?.data?.data?.mainuserinfo?.email}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {tabIndex === 1 && (
                                    <div class="col-lg-11 mt-4">
                                        <div class="col-lg-12 ">
                                            <ScrollMenu
                                                itemClassName="EndorsementCircleCard"
                                                LeftArrow={LeftArrow}
                                                RightArrow={RightArrow}
                                                transitionDuration={500}
                                                transitionEase={easingFunctions['linear']}
                                                scrollContainerClassName={'scrollmenuclasssubscrollbar'}
                                            >
                                                {fetchuserportfolio_Query?.data?.data?.mainuserinfo?.userendorsments?.map((item, index) => {
                                                    var id = index;
                                                    return (
                                                        <EndorsementCircleCard
                                                            title={id}
                                                            itemId={id}
                                                            key={id}
                                                            item={item}
                                                            index={index}
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                    );
                                                })}
                                            </ScrollMenu>
                                        </div>
                                    </div>
                                )}
                                {tabIndex === 2 && (
                                    <div class="col-lg-11 mt-4">
                                        <div class="col-lg-12 ">
                                            <ScrollMenu
                                                itemClassName="EndorsementCircleCard"
                                                LeftArrow={LeftArrow}
                                                RightArrow={RightArrow}
                                                transitionDuration={500}
                                                transitionEase={easingFunctions['linear']}
                                                scrollContainerClassName={'scrollmenuclasssubscrollbar'}
                                            >
                                                {fetchuserportfolio_Query?.data?.data?.mainuserinfo?.usercircle?.map((item, index) => {
                                                    var id = index;
                                                    return <EndorsementCircleCard title={id} itemId={id} key={id} item={item} index={index} />;
                                                })}
                                            </ScrollMenu>
                                        </div>
                                    </div>
                                )}
                                {tabIndex === 3 && (
                                    <div class="col-lg-12 p-0 mt-4">
                                        <div class="row m-0 w-100 d-flex">
                                            {fetchuserportfolio_Query?.data?.data?.mainuserinfo?.paymentmethods?.map(function (paymentitem, paymentindex) {
                                                return (
                                                    <div class={' col-xl-6 col-lg-6 col-md-6 font-15 fw-500 d-flex align-items-center mb-3 '}>
                                                        <div class={' mr-1 '} style={{ width: 5, height: 5, background: '#ccc', borderRadius: 100 }}></div>
                                                        <div class="col-lg-10 d-flex flex-column p-0">
                                                            <p class="m-0 p-0 fw-500 font-15 text-capitalize text-white">{paymentitem.pmethodname}</p>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                                {fetchuserportfolio_Query?.data?.data?.mainuserinfo?.type == 'user' && tabIndex === 4 && (
                                    <div class="col-lg-12 p-0 mt-4">
                                        <div class="row m-0 w-100 d-flex">
                                            {fetchuserportfolio_Query?.data?.data?.mainuserinfo?.skills.map(function (skillitem, skillindex) {
                                                return (
                                                    <div class={' col-xl-6 col-lg-6 col-md-6 font-15 fw-500 d-flex align-items-center mb-3 '}>
                                                        <div class={' mr-1 '} style={{ width: 5, height: 5, background: '#ccc', borderRadius: 100 }}></div>
                                                        <div class="col-lg-10 d-flex flex-column p-0">
                                                            <p class="m-0 p-0 fw-500 font-15 text-capitalize text-white">{skillitem.skillname}</p>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div class="col-xl-10 col-lg-11 pt-5 pr-3 pb-0 pl-md-0 pr-md-0">
                            <div class="col-lg-12 mt-3 mb-2 p-0 d-flex justify-content-end">
                                {fetchuserportfolio_Query?.data?.data?.maincatsinsproj.length > 1 && (
                                    <div class="dropdowncontainer  mr-2">
                                        <input class="dropdown" type="checkbox" checked={checked} id="dropdown" name="dropdown" hidden />
                                        <label
                                            onClick={() => {
                                                setChecked(!checked);
                                            }}
                                            className={' d-flex justify-content-center align-items-center for-dropdown'}
                                            for="dropdown"
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {currentcategory === '' ? 'Categories' : currentcategory.catname} <i className="bx bx-chevron-down ml-2" style={{ fontSize: '20px' }}></i>
                                        </label>
                                        <div class="dropdownmenu">
                                            <p
                                                class="dropdownlink"
                                                onClick={() => {
                                                    history.push('/' + userprofilelink + '/all' + '/all');
                                                }}
                                            >
                                                All Categories
                                            </p>
                                            {fetchuserportfolio_Query?.data?.data?.maincatsinsproj.map(function (item, index) {
                                                return (
                                                    <p
                                                        class="dropdownlink"
                                                        onClick={() => {
                                                            var catname = item.catname.replace(/ /g, '');
                                                            catname = catname.toLowerCase();
                                                            history.push('/' + userprofilelink + '/' + catname + '/all');
                                                        }}
                                                    >
                                                        {item.catname}
                                                    </p>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div class={' row m-0 d-flex align-items-end w-100 '}>
                                <div class="col-lg-12 col-md-12 col-sm-12 p-0 pl-md-2 pr-md-2">
                                    <ScrollMenu
                                        itemClassName="slideshowCard"
                                        LeftArrow={LeftArrow}
                                        RightArrow={RightArrow}
                                        transitionDuration={500}
                                        transitionEase={easingFunctions['linear']}
                                        scrollContainerClassName={'scrollmenuclasssubscrollbar'}
                                    >
                                        <div
                                            class={
                                                projectindex === ''
                                                    ? `${styles.scrollhorizontalcard} ${styles.scrollhorizontalcardactive}` + ' mr-2 col-lg-12 m-0 w-100 p-0 '
                                                    : `${styles.scrollhorizontalcard}` + ' mr-2 col-lg-12 m-0 w-100 '
                                            }
                                            onClick={() => {
                                                history.push('/' + userprofilelink + '/' + catname + '/all');
                                            }}
                                            style={{ zIndex: 1000 }}
                                        >
                                            <p class="text-white text-secondaryhover m-0 p-0" style={{ fontWeight: currentchecked == 'favouritecontent' ? 600 : 500 }}>
                                                Favourite Content
                                            </p>
                                        </div>
                                        {projectsarray.map((item, index) => {
                                            var id = index;
                                            return <ProjectsCard title={id} itemId={id} key={id} item={item} index={index} />;
                                        })}
                                    </ScrollMenu>
                                </div>
                            </div>
                            <div class="col-lg-12 p-0">
                                <hr class="p-0 m-0" style={{ borderColor: '#fff' }} />
                            </div>
                            <div class="col-lg-12 p-2 pt-4" style={{ minHeight: '60vh' }}>
                                {projectindex === '' && (
                                    <div class="row m-0 w-100">
                                        {fetchuserportfolio_Query?.data?.data?.topratedimgs?.allimgs?.map((item, index) => {
                                            var finalimage = '';
                                            if (item.type == 'image') {
                                                finalimage = item.url;
                                            } else {
                                                finalimage = item.thumb;
                                            }
                                            return (
                                                <div
                                                    class={' col-lg-4 col-md-6 mb-5 '}
                                                    onClick={() => {
                                                        favcontentclick(item.name);
                                                    }}
                                                >
                                                    <div class={styles.maincont + ' w-100 '}>
                                                        <div style={{ width: '100%', height: '300px' }}>
                                                            <IKImage path={finalimage} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                                        </div>
                                                        <div class={styles.cardcontainer}>
                                                            <span class="text-capitalize" style={{ fontSize: '20px', fontWeight: '700' }}>
                                                                {item.name}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                                {projectindex !== '' && (
                                    <div class="row m-0 w-100 mt-3">
                                        <ul class={lightgallerystyles.demo_gallery + ' p-0 h-100 m-0 animate__animated animate__slideInUp w-100'}>
                                            <LightGallery
                                                onInit={onInit}
                                                speed={500}
                                                selector=".imgsvidsmusiconly"
                                                plugins={[lgThumbnail, pager, fullScreen, autoplay, lgVideo, video]}
                                                elementClassNames={lightgallerystyles.lightgalleryclass}
                                                pager={true}
                                                videojs={true}
                                            >
                                                <div class="row m-0 w-100 d-flex text-center justify-content-start align-items-center w-100">
                                                    {projectsarray[projectindex]?.projcontents != undefined &&
                                                        projectsarray[projectindex]?.projcontents.length != 0 &&
                                                        projectsarray[projectindex]?.projcontents.map((item, index) => {
                                                            if (item.type == 'image') {
                                                                return (
                                                                    <li
                                                                        class={
                                                                            lightgallerystyles.liclassgallery +
                                                                            ' w-100 h-100 col-lg-3 col-md-6 col-sm-12 animate__animated animate__slideInUp d-flex text-center justify-content-center align-items-center '
                                                                        }
                                                                        data-pinterest-text=""
                                                                        data-tweet-text=""
                                                                    >
                                                                        <a
                                                                            data-responsive={
                                                                                imagesserverbaselink +
                                                                                item.url +
                                                                                ' 375,' +
                                                                                imagesserverbaselink +
                                                                                item.url +
                                                                                '  480' +
                                                                                imagesserverbaselink +
                                                                                item.url +
                                                                                ', 800'
                                                                            }
                                                                            data-src={imagesserverbaselink + item.url}
                                                                            class={'imgsvidsmusiconly w-100'}
                                                                        >
                                                                            <img src={imagesserverbaselink + item.url} />
                                                                            <div class={lightgallerystyles.demo_gallery_poster}>
                                                                                <img
                                                                                    src="https://spotearned.com/storage/spotlogo/spotcircleshorizontal.png"
                                                                                    class="m-auto"
                                                                                    style={{ width: '60%', top: 0, bottom: 0, left: 0, right: 0, marginLeft: 0, marginTop: 0 }}
                                                                                />
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                );
                                                            } else if (item.type == 'music') {
                                                                return (
                                                                    <li
                                                                        class={
                                                                            lightgallerystyles.liclassgallery +
                                                                            ' w-100 h-100 col-lg-3 col-md-6 col-sm-12 animate__animated animate__slideInUp d-flex text-center justify-content-center align-items-center '
                                                                        }
                                                                    >
                                                                        <a
                                                                            class={'imgsvidsmusiconly w-100'}
                                                                            data-video={
                                                                                '{"source": [{"src":"' +
                                                                                imagesserverbaselink +
                                                                                item.url +
                                                                                '", "type":"video/mp4"}], "tracks": [{"src": "{/videos/title.txt", "kind":"captions", "srclang": "en", "label": "English", "default": "true"}], "attributes": {"preload": false, "playsinline": true, "controls": true}}'
                                                                            }
                                                                            data-poster={imagesserverbaselink + item.thumb}
                                                                        >
                                                                            <img
                                                                                style={{ position: 'relative', objectFit: 'contain' }}
                                                                                class={'img-responsive img-fluid '}
                                                                                src={imagesserverbaselink + item.thumb}
                                                                            />
                                                                            <div class={lightgallerystyles.demo_gallery_poster}>
                                                                                <img
                                                                                    src="https://spotearned.com/storage/spotlogo/Spotlogo_horizontal-yellow-Recovered-removebg-preview.png"
                                                                                    class="m-auto"
                                                                                    style={{ width: '60%', top: 0, bottom: 0, left: 0, right: 0, marginLeft: 0, marginTop: 0 }}
                                                                                />
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                );
                                                            } else if (item.type == 'video') {
                                                                return (
                                                                    <li
                                                                        class={
                                                                            lightgallerystyles.liclassgallery +
                                                                            ' w-100 h-100 col-lg-3 col-md-6 col-sm-12 animate__animated animate__slideInUp d-flex text-center justify-content-center align-items-center '
                                                                        }
                                                                        data-src={item.url}
                                                                    >
                                                                        <a class={'imgsvidsmusiconly w-100'} data-src={item.url}>
                                                                            <img
                                                                                style={{ position: 'relative', objectFit: 'contain' }}
                                                                                class={'img-responsive img-fluid '}
                                                                                src={imagesserverbaselink + item.thumb}
                                                                            />
                                                                            <div class={lightgallerystyles.demo_gallery_poster}>
                                                                                <img
                                                                                    src="https://spotearned.com/storage/spotlogo/Spotlogo_horizontal-yellow-Recovered-removebg-preview.png"
                                                                                    class="m-auto"
                                                                                    style={{ width: '60%', top: 0, bottom: 0, left: 0, right: 0, marginLeft: 0, marginTop: 0 }}
                                                                                />
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                );
                                                            } else if (item.type == 'document') {
                                                                return (
                                                                    <li
                                                                        class={
                                                                            lightgallerystyles.liclassgallery +
                                                                            ' w-100 h-100 col-lg-3 col-md-6 col-sm-12 animate__animated animate__slideInUp d-flex text-center justify-content-center align-items-center '
                                                                        }
                                                                        data-poster={imagesserverbaselink + item.thumb}
                                                                    >
                                                                        <a class="w-100" href={imagesserverbaselink + item.url} download={true} target="_blank">
                                                                            <img style={{ position: 'relative' }} class={'img-responsive img-fluid '} src={imagesserverbaselink + item.thumb} />

                                                                            <div class={lightgallerystyles.demo_gallery_poster}>
                                                                                <img
                                                                                    src="https://spotearned.com/storage/spotlogo/Spotlogo_horizontal-yellow-document.png"
                                                                                    class="m-auto"
                                                                                    style={{ width: '60%', top: 0, bottom: 0, left: 0, right: 0, marginLeft: 0, marginTop: 0 }}
                                                                                />
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                );
                                                            }
                                                        })}
                                                </div>
                                            </LightGallery>
                                        </ul>
                                        {projectsarray[projectindex]?.projcontents.length == 0 && (
                                            <div class="col-lg-12 d-flex flex-column justify-content-center align-items-center pt-5 pb-5" style={{ height: '60vh' }}>
                                                <img src={'https://spotearned.com/storage/images/nocontent.png'} style={{ maxWidth: '45%', maxHeight: '30%' }} />
                                                <p class="m-0 p-0 text-white" style={{ fontSize: '18px' }}>
                                                    No Content
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <Modal show={show} onHide={() => setShow(false)} centered size="md">
                        <Modal.Header closeButton style={{ paddingBottom: 0, backgroundColor: 'transparent' }}>
                            <div className={' ml-auto mr-auto mb-0 pb-0 '} style={{ color: 'white' }}>
                                <p class="m-0 p-0" style={{ fontSize: '28px' }}>
                                    Request Quotation
                                </p>
                            </div>
                            <button
                                className="close-modal-button"
                                onClick={() => {
                                    setShow(false);
                                }}
                            >
                                <span></span>
                            </button>
                        </Modal.Header>
                        <Modal.Body className="mt-0 pt-0">
                            <div class="row m-0">
                                {!isloggedincontext && (
                                    <>
                                        <div class="col-lg-6 mb-5 p-sm-0">
                                            <label class=" field field_v2 field-website field-website-apply w-100 ">
                                                <input
                                                    class="field__input text-capitalize"
                                                    value={sendquotationpayload.sreqname}
                                                    onChange={(event) => {
                                                        setsendquotationpayload({ ...sendquotationpayload, sreqname: event.target.value });
                                                    }}
                                                    placeholder="Please Write Your Full Name"
                                                />
                                                <span class="focus_input100">
                                                    <i class="input-icon fa fa-user"></i>
                                                </span>
                                                <span class="field__label_wrap">
                                                    <span class="field__label">Full Name</span>
                                                </span>
                                            </label>
                                        </div>
                                        <div class="col-lg-6 mb-5 p-sm-0">
                                            <label class=" field field_v2 field-website field-website-apply w-100 ">
                                                <input
                                                    class="field__input text-capitalize"
                                                    value={sendquotationpayload.sreqemail}
                                                    onChange={(event) => {
                                                        setsendquotationpayload({ ...sendquotationpayload, sreqemail: event.target.value });
                                                    }}
                                                    placeholder="Please Write Your Email"
                                                />
                                                <span class="focus_input100">
                                                    <i class="input-icon fa fa-user"></i>
                                                </span>
                                                <span class="field__label_wrap">
                                                    <span class="field__label">Email</span>
                                                </span>
                                            </label>
                                        </div>
                                        <div class="col-lg-12 mb-3 p-sm-0">
                                            <label class=" field field_v2 field-website field-website-apply w-100 ">
                                                <span class="field__label_wrap">
                                                    <span class="field__label_select">Phone number</span>
                                                </span>
                                                <PhoneInput
                                                    containerStyle={{
                                                        marginTop: '20px',
                                                    }}
                                                    defaultCountry="eg"
                                                    country={'eg'}
                                                    placeholder=""
                                                    enableSearch={true}
                                                    disableSearchIcon={true}
                                                    value={sendquotationpayload.sreqmobile}
                                                    onChange={(event) => {
                                                        setsendquotationpayload({ ...sendquotationpayload, sreqmobile: event });
                                                    }}
                                                />
                                            </label>
                                        </div>
                                    </>
                                )}
                                <div class="col-lg-12 mb-4 p-sm-0 mt-3">
                                    <label class=" field field_v2 field-website field-website-apply w-100 ">
                                        <TextareaAutosize
                                            class="field__input text-capitalize"
                                            value={sendquotationpayload.sreqbody}
                                            onChange={(event) => {
                                                setsendquotationpayload({ ...sendquotationpayload, sreqbody: event.target.value });
                                            }}
                                            placeholder="Please Write Your Full Name"
                                        />
                                        <span class="focus_input100">
                                            <i class="input-icon fa fa-user"></i>
                                        </span>
                                        <span class="field__label_wrap">
                                            <span class="field__label">Description</span>
                                        </span>
                                    </label>
                                </div>
                                <div onClick={() => {}} className={loginstyles.signinbtn_cont}>
                                    <button
                                        type="button"
                                        className={loginstyles.form_btn}
                                        onClick={() => {
                                            var tempsendquotationpayload = { ...sendquotationpayload };
                                            var runfunc = false;
                                            if (tempsendquotationpayload.sreqbody.length != 0) {
                                                if (isloggedincontext) {
                                                    runfunc = true;
                                                } else {
                                                    if (
                                                        tempsendquotationpayload.sreqname.length != 0 &&
                                                        tempsendquotationpayload.sreqemail.length != 0 &&
                                                        tempsendquotationpayload.sreqmobile.length != 0
                                                    ) {
                                                        runfunc = true;
                                                    }
                                                }
                                            }
                                            if (runfunc) {
                                                tempsendquotationpayload.sreqtouserid = fetchuserportfolio_Query.data.data.mainuserinfo.id;
                                                SendRequestMutation.mutate(tempsendquotationpayload);
                                            } else {
                                                NotificationManager.warning('', 'Please fill the missing inputs');
                                            }
                                        }}
                                        disabled={SendRequestMutation.isLoading}
                                    >
                                        {/* <i className={signpreloaderdisplay}></i> Sign In */}
                                        {!SendRequestMutation.isLoading && <span>Send</span>}
                                        {SendRequestMutation.isLoading && (
                                            <>
                                                <i class="fa fa-spinner fa-spin"></i> <span>Send</span>
                                            </>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
            {fetchuserportfolio_Query.isSuccess && !fetchuserportfolio_Query.isLoading && !fetchuserportfolio_Query.data.data.status && (
                <div class="col-lg-12 ">
                    <p>We couldnt find your page</p>
                </div>
            )}
        </div>
    );
};

export default Home;
const easingFunctions = {
    linear: (t) => t,
};
