
import React, { Component,useState } from "react";
// import Cookies from 'universal-cookie';
import axios  from 'axios';
import { useHistory } from "react-router-dom";
export const DashboardRoutedispcontext = React.createContext()

  export const DashboardRoutedispcontext_provider = (props) => {  
    let history = useHistory();
    const axiosheaders = { 
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };
    const [ischatmessagesfetchingcontext, setischatmessagesfetchingcontext] = useState(false);
    const [openmessagesheadercontext, setopenmessagesheadercontext] = useState(false);
    const [chatopeneduserobjcontext, setchatopeneduserobjcontext] = useState('');
    const [ischatopenedcontext, setischatopenedcontext] = useState(false);
    const [chatopenedmessagescontext, setchatopenedmessagescontext] = useState([]);
    

  const setroutedispatcherfunccontext = (route) => {
   history.push(route)
  }
  
  const chathead_openchatfunccontext = (userobj,arrindex)=>{
    setischatmessagesfetchingcontext(true)
    setopenmessagesheadercontext(true)
    setchatopeneduserobjcontext(userobj)
    setischatopenedcontext(true)
    axios({
        method: 'post',
        url: 'https://spotearned.com/generalall/getmessages',
        headers:axiosheaders,
        data:{destuserid:userobj.id}
    })
    .then(response =>{
        console.log(response.data)
        setchatopenedmessagescontext(response.data.messages)
        // scrollToBottomofmessageschat()
        setischatmessagesfetchingcontext(false)
        document.getElementById('chatmessagesdiv').scrollIntoView({ behavior: 'smooth', block: 'end' });
    })
    .catch(error => {
        console.log(error)
        alert("Error in retrieving messages")
    });
}



    return (
      <DashboardRoutedispcontext.Provider
        value={{
          setroutedispatcherfunccontext,
          ischatmessagesfetchingcontext,setischatmessagesfetchingcontext,
          openmessagesheadercontext,setopenmessagesheadercontext,
          chatopeneduserobjcontext,setchatopeneduserobjcontext,
          ischatopenedcontext,setischatopenedcontext,
          chatopenedmessagescontext,setchatopenedmessagescontext,
          chathead_openchatfunccontext,
          }}>
        {
        props.children
        }
      </DashboardRoutedispcontext.Provider>
    )
  
}
